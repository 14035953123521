<template>
	<div class="aside-bar">
		
			<div class="aside-list">
				<!-- 1.差旅标准 -->
				<div class="clbz">
					<div class="content">
						<div class="h5">
							机票差旅标准-{{tkQuery.useNameclbz}}
						</div>
						<div class="body">{{tkQuery.clbzText}}</div>
						
					</div>
					<div class="title">
						<span class="iconfont yj-icon-tianjiarzr"></span>
						
						<span style="display: inline-block;margin-left: 5px;">
							<div class="clbz-label">
								差旅标准
							</div>
							<div class="clbz-name">
								{{tkQuery.useNameclbz}}
							</div>
						</span>
					</div>
					
					
				</div>
				<!-- 2.预算标准 -->
				
			</div>
			
			
	</div>
</template>

<script>
	export default {
		name:"AsideRight",
		data(){
			return {
				
			}
			
		},
		props:{
			tkQuery:{
				type:[Object],
				default(){
					return {}
				}
			}
		}
	}			
</script>

<style scoped="scoped" lang="scss">
	.aside-bar{
		width: 120px;
		position: relative;
		display: inline-block;
		right: -660px;
		top: -60px;
		-webkit-transition: top .2s ease-in;
		-moz-transition: top .2s ease-in;
		transition: top .2s ease-in;
		font-family: '微软雅黑';
		.clbz{
			position: relative;
			cursor: pointer;
			
			font-size: 12px;
			color: #5678a8;
			line-height: 14px;
			// background-color: white;
			text-align: right;
			.title{
				padding: 6px 2px 6px 9px;
				background-repeat: no-repeat;
				background-position: 0;
				display: inline-block;
				vertical-align: middle;
				min-height: 40px;
				
				background-color: white;
				
				text-align: left;
				width: 100px;
				background: #fff;
				.clbz-label{
					
					height: 12px;
					font-size: 12px;
					font-weight: 400;
					color: #99A5BB;
					line-height: 18px;
				}
				.clbz-name{
					height: 12px;
					font-size: 12px;
					font-weight: 400;
					text-decoration: underline;
					color: #1C83ED;
					line-height: 21px;
				}
			}
			.content{
				width: 246px;
				left: -246px;
				padding: 15px ;
				text-align: center;
				
				cursor: auto;
				position: absolute;
				top: 0;
				background: white;
				z-index: 100;
				display: none;
				border-radius: 5px;
				text-align: left;
				.h5{
					height: 13px;
					font-size: 13px;
					font-weight: 500;
					color: #000000;
					
				}
				.body{
					height: 13px;
					font-size: 13px;
					font-weight: 500;
					color: orange;
					margin-top: 15px;
				}
			}
		}
		.clbz:hover{
			box-shadow: 0 6px 20px 2px rgba(0,0,0,0.2);
			background: white;
			z-index: 101;
			border-left: none;
			.title{
				color: #1C83ED;
			}
			.content{
				display: block;
				box-shadow: 0 6px 20px 2px rgba(0,0,0,0.2);
			}
		}
	
	}
	
	
</style>
