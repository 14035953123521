<template>
	<div class="zheng-cai-info">
		<el-card class="setCard">
			<h1 slot="header" class="text-left ">
				政采信息
				<span class="zheng-cai-tips">(预算单位和公务卡只能选其一)</span>
			</h1>
			<div >
				<!-- <el-menu 
					:default-active="activeIndex" 
					class="el-menu-demo setBorder"  
					active-text-color="#40AFFF" 
					mode="horizontal" 
					@select="chooseCurrentType">
					<el-menu-item index="0">预算单位</el-menu-item>
					<el-menu-item index="1">公务卡</el-menu-item>
				</el-menu> -->
				<el-tabs v-model="gpValidTypeCopy" :tab-position="'left'" @tab-click="chooseCurrentType">
					<el-tab-pane :key="1" label="预算单位" name="1">
						<div class="el-tab-pane-tips">所选预算单位涉及报销, 请选择预算单位后,再进行下一步操作</div>
						<div class="el-tab-pane-div">
							<el-select
								v-model="budgetUnitNameCopy" 
								:loading="loadingBudgetUnitList"
								value-key
								automatic-dropdown
								filterable
								@focus="getBudgetUnitList()"
								@change="getCurrentBudgetUnit" 
								placeholder="请选择预算单位" 	
								style="width:100%"
								>
							    <el-option
							      v-for="(item,index) in budgetUnitList"
							      :key="index"
							      :label="item.budgetUnitName"
							      :value="item.budgetUnitName">
							    </el-option>
							</el-select> 
						</div>
					</el-tab-pane>
					<el-tab-pane :key="2" label="公务卡" name="2">
						<div class="el-tab-pane-tips">所选公务卡涉及报销, 请选择公务卡后,再进行下一步操作</div>
						<div class="el-tab-pane-div">
							<el-select
								v-model="bankNameCopy" 
								:loading="loadingBankList"
								value-key
								automatic-dropdown
								filterable
								@focus="getBankList()"
								@change="getCurrentBank" 
								placeholder="请选择公务卡" 	
								style="width:100%"
								>
							    <el-option
							      v-for="(item,index) in bankList"
							      :key="index"
							      :label="item.bankName"
							      :value="item.bankName">
							    </el-option>
							</el-select> 
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
			
		</el-card>
	</div>
</template>

<script>
	export default{
		name:'ZhengCaiInfo',
		data(){
			return{
				gpValidTypeCopy:'1', // 1.预算单位  2.公务卡
				// 预算单位
				loadingBudgetUnitList:false,
				budgetUnitNameCopy:"",
				budgetUnitList:[],
				// 公务卡
				loadingBankList:false, 
				bankNameCopy:'',
				bankList:[],
				
			}
		},
		props:{
			budgetUnitName:{
				type:[String],
				default(){
					return''
				}
			},
			bankName:{
				type:[String],
				default(){
					return''
				}
			},
			gpValidType:{
				type:[String],
				default(){
					return'1'
				}
			}
		},
		watch:{
			gpValidType(val){
				this.gpValidTypeCopy = val
			},
			budgetUnitName(val){
				this.budgetUnitNameCopy = val
			},
			bankName(val){
				this.bankNameCopy = val
			},
		},
		methods:{
			// 		@chooseBudgetUnit="chooseBudgetUnit"  
			// 		@chooseBank="chooseBank"
			// 		@changeGpValidType="changeGpValidType"
			
			
			// 选择当前类型
			chooseCurrentType(tab, event){
				this.$emit('changeGpValidType',tab.name)
				// this.gpValidType = tab.name
			},
			// 获取预算单位列表
			getBudgetUnitList(){
				var queryObj = {};
				this.$conn.getConn('tkBook.getBudgetUnitList')(queryObj, (res) => {
				  var list = res.data || [];
				  this.budgetUnitList = list;
				  // 如果只有一条数据默认,选择
				  if (list.length == 1 && !this.budgetUnitName) {
				    var budgetUnitName = list[0]['budgetUnitName'] || '';
				    this.getCurrentBudgetUnit(budgetUnitName);
				  }
				
				}, (err) => {
				
				})
			},
			// 获取当前选择的预算单位
			getCurrentBudgetUnit(val){
				this.$emit('chooseBudgetUnit',val)
			},
			
			
			// 获取公务卡列表
			getBankList(){
				var queryObj = {};
				this.$conn.getConn('tkBook.getGpBankList')(queryObj, (res) => {
				  var list = res.data || [];
				  this.bankList = list;
				  // 如果只有一条数据默认,选择
				  if (list.length == 1 && !this.bankName) {
				    var bankName = list[0]['bankName'] || '';
				    this.getCurrentBank(bankName) ;
				  }
				}, (err) => {
				
				})
			},
			// 选中公务卡回调
			getCurrentBank(val){
					this.$emit('chooseBank',val)
					
			},
			
		}

	}
</script>

<style scoped="scoped" lang="scss">
	/deep/ .el-input__inner{
		border: 0;
		padding: 0;
		border-radius: 0;
		border-bottom: 1px solid #C4CDDD;
		font-size: 15px;
	}
	.zheng-cai-info{
		margin-top: 20px;
		.zheng-cai-tips{
			font-size: 12px;
			color: orange;
		}
		.el-tab-pane-tips{
			text-align: left;
			color: darkgray;
		}
		.el-tab-pane-div{
			margin-top: 20px;
		}
	}
</style>
