<template>
	<div class="tk-filter">
		<div class="tk-filter-left">
			<!-- 直飞、经停 -->
			<span v-for="(stopItem,stopKey) in  filterMapCopy['stopNumMap']" 
				:key="stopKey" 
				class="stop-item"
				:class="{'checked':stopItem.checked}"

				@click="chooseFilterItem(stopItem,'stopNumMap')">
				<span class="iconfont yj-icon-ok" v-show="stopItem.checked"></span>
				<span class="iconfont yj-icon-icon-no-checkbox" v-show="!stopItem.checked"></span>
				{{stopItem.name}}
			</span>
	
			
			<!-- 舱位 -->
			<el-popover
			  placement="bottom-start"
			  width="260"
			  trigger="click"
			  v-model="showPopover1"
			  :popper-append-to-body="false"
			  >	
			  <!-- 单项 -->
			  <div slot="reference" class="filter-item" :class="{'isCheckedFilter':calcStyleChoose(filterMapCopy.cabinTypeMap)}">
			  		舱位选择
			  		<span class="el-icon-arrow-down" v-show="!showPopover1"></span>
			  		<i class="el-icon-arrow-up" v-show="showPopover1"></i>
			  </div>
			  
			  <!-- 弹窗 -->
			  <div>
				  <div class="filter-header">
					  <span>舱位</span>
					   <i class="el-icon-close" @click="showPopover1 = false "></i>
				  </div>
				  <div class="filter-content">
					  <div>
						  <div class="filter-item"  
						  :class="{'checked':filterItem.checked,'disabled':filterItem.filterCount<1,'isFirstFilter':firstFilterMapStr == 'cabinTypeMap'}"
						  v-for="(filterItem,filterkey) in filterMapCopy.cabinTypeMap" 
						  :key="filterkey"
						  @click="chooseFilterItem(filterItem,'cabinTypeMap')"
						  >
							  <span class="filter-check-item">
								  <span class="iconfont yj-icon-ok" v-show="filterItem.checked"></span>
								  <span class="iconfont yj-icon-icon-no-checkbox" v-show="!filterItem.checked"></span>
								  <span class="filter-check-item-name">{{filterItem.name}}</span>
							  </span>
							  <span>￥{{filterItem.minPrice}}</span>
							 
						  </div>
						  
					  </div>
				  </div>
			  </div>
			 
			</el-popover>
			<!-- 起飞、降落时段 -->
			<el-popover
			  placement="bottom-start"
			  width="520"
			  trigger="click"
			  v-model="showPopover2"
			   :popper-append-to-body="false"
			  >	
			  <!-- 单项 -->
			  <div slot="reference" class="filter-item" :class="{'isCheckedFilter':(calcStyleChoose(filterMapCopy.depTimeMap)  || calcStyleChoose(filterMapCopy.arrTimeMap) )}" >
						  起抵时段
						  <span class="el-icon-arrow-down" v-show="!showPopover2"></span>
						  <i class="el-icon-arrow-up" v-show="showPopover2"></i>	  
			  </div>
			  
			  <!-- 弹窗 -->
			  <div class="popover-content">
				  <div class="popover-content-item popover-content-item-left">
					  <div class="filter-header">
					  	   <span>起飞时段</span>
					  	    <!-- <i class="el-icon-close" @click="showPopoverCabin = false "></i> -->
					  </div>
					  <div class="filter-content">
					    	  <div class="filter-item" 
							  :class="{'checked':filterItem.checked,'disabled':filterItem.filterCount<1,'isFirstFilter':firstFilterMapStr == 'depTimeMap'}"
							  v-for="(filterItem,filterkey) in filterMapCopy.depTimeMap" 
							  :key="filterkey"
							   @click="chooseFilterItem(filterItem,'depTimeMap')"
							  >
									<span class="filter-check-item">
										<span class="iconfont yj-icon-ok" v-show="filterItem.checked"></span>
										<span class="iconfont yj-icon-icon-no-checkbox" v-show="!filterItem.checked"></span>
										<span class="filter-check-item-name">{{filterItem.name}}</span>
									</span>
									<span>￥{{filterItem.minPrice}}</span>
					    	  </div>
					  </div>
				  </div>
				  <div class="popover-content-item popover-content-item-right">
				  		<div class="filter-header">
				  			  <span>抵达时段</span>
				  			   <i class="el-icon-close" @click="showPopover2 = false "></i>
				  		</div>
				  		<div class="filter-content">
				  		    <div class="filter-item" 
							:class="{'checked':filterItem.checked,'disabled':filterItem.filterCount<1,'isFirstFilter':firstFilterMapStr == 'arrTimeMap'}"
							v-for="(filterItem,filterkey) in filterMapCopy.arrTimeMap" 
							:key="filterkey"
							@click="chooseFilterItem(filterItem,'arrTimeMap')"
							>
				  		  	  <span class="filter-check-item">
				  		  	  	<span class="iconfont yj-icon-ok" v-show="filterItem.checked"></span>
				  		  	  	<span class="iconfont yj-icon-icon-no-checkbox" v-show="!filterItem.checked"></span>
				  		  	  	<span class="filter-check-item-name">{{filterItem.name}}</span>
				  		  	  </span>
				  		  	  <span>￥{{filterItem.minPrice}}</span>
				  		  	 
				  		    </div>
				  								  
				  		</div>
				  </div>
				  
			  </div>
			 
			</el-popover>
			
			<!-- 航空公司 -->
			<el-popover
			  placement="bottom-start"
			  width="260"
			  trigger="click"
			  v-model="showPopover3"
			   :popper-append-to-body="false"
			  >	
			  <!-- 单项 -->
			  <div slot="reference" class="filter-item" :class="{'isCheckedFilter':calcStyleChoose(filterMapCopy.airlineMap)}" >
			  		航空公司
			  		<span class="el-icon-arrow-down" v-show="!showPopover3"></span>
			  		<i class="el-icon-arrow-up" v-show="showPopover3"></i>
			  </div>
			  
			  <!-- 弹窗 -->
			  <div>
				  <div class="filter-header">
					  <span>航空公司</span>
					   <i class="el-icon-close" @click="showPopover3 = false "></i>
				  </div>
				  <div class="filter-content">
					  <div>
						  <div class="filter-item" 
						  :class="{'checked':filterItem.checked,'disabled':filterItem.filterCount<1,'isFirstFilter':firstFilterMapStr == 'airlineMap'}"
						  v-for="(filterItem,filterkey) in filterMapCopy.airlineMap" 
						  :key="filterkey"
						  @click="chooseFilterItem(filterItem,'airlineMap')"
						  >
							  <span class="filter-check-item company">
							  	<span class="iconfont yj-icon-ok" v-show="filterItem.checked"></span>
							  	<span class="iconfont yj-icon-icon-no-checkbox" v-show="!filterItem.checked"></span>
								<img class="filter-check-item-img" :src="filterItem.img" alt="" height="12" width="12">
							  	<span class="filter-check-item-name">{{filterItem.name}}</span>
							  </span>
							  <span>￥{{filterItem.minPrice}}</span>
							 
						  </div>
						  
					  </div>
				  </div>
			  </div>
			 
			</el-popover>
			
			
			<!-- 起抵机场 -->
			<el-popover
			  placement="bottom-start"
			  width="520"
			  trigger="click"
			  v-model="showPopover4"
			   :popper-append-to-body="false"
			  >	
			  <!-- 单项 -->
			  <div slot="reference" class="filter-item" :class="{'isCheckedFilter':(calcStyleChoose(filterMapCopy.depAirportMap) || calcStyleChoose(filterMapCopy.arrAirportMap) )}">
						  起抵机场
						  <span class="el-icon-arrow-down" v-show="!showPopover4"></span>
						  <i class="el-icon-arrow-up" v-show="showPopover4"></i>	  
			  </div>
			  
			  <!-- 弹窗 -->
			  <div class="popover-content">
				  <div class="popover-content-item popover-content-item-left">
					  <div class="filter-header">
					  	   <span>起飞机场</span>
					  	    <!-- <i class="el-icon-close" @click="showPopoverCabin = false "></i> -->
					  </div>
					  <div class="filter-content">
					  	
					    	  <div class="filter-item" 
							  :class="{'checked':filterItem.checked,'disabled':filterItem.filterCount<1,'isFirstFilter':firstFilterMapStr == 'depAirportMap'}"
							  v-for="(filterItem,filterkey) in filterMapCopy.depAirportMap" 
							  :key="filterkey"
							  @click="chooseFilterItem(filterItem,'depAirportMap')"
							  >
					    		<span class="filter-check-item">
					    			<span class="iconfont yj-icon-ok" v-show="filterItem.checked"></span>
					    			<span class="iconfont yj-icon-icon-no-checkbox" v-show="!filterItem.checked"></span>
					    			<span class="filter-check-item-name">{{filterItem.name}}</span>
					    		</span>
					    		<span>￥{{filterItem.minPrice}}</span>
					    	  </div>
					  </div>
				  </div>
				  <div class="popover-content-item popover-content-item-right">
				  		<div class="filter-header">
				  			  <span>抵达机场</span>
				  			   <i class="el-icon-close" @click="showPopover4 = false "></i>
				  		</div>
				  		<div class="filter-content">
				  		    <div class="filter-item" 
							:class="{'checked':filterItem.checked,'disabled':filterItem.filterCount<1,'isFirstFilter':firstFilterMapStr == 'arrAirportMap'}"
							v-for="(filterItem,filterkey) in filterMapCopy.arrAirportMap" 
							:key="filterkey"
							@click="chooseFilterItem(filterItem,'arrAirportMap')"
							>
				  		  	  <span class="filter-check-item">
				  		  	  	<span class="iconfont yj-icon-ok" v-show="filterItem.checked"></span>
				  		  	  	<span class="iconfont yj-icon-icon-no-checkbox" v-show="!filterItem.checked"></span>
				  		  	  	<span class="filter-check-item-name">{{filterItem.name}}</span>
				  		  	  </span>
				  		  	  <span>￥{{filterItem.minPrice}}</span>
				  		    </div>
				  								  
				  		</div>
				  </div>
				  
			  </div>
			 
			</el-popover>
			<!-- 机型 -->
			<el-popover
			  placement="bottom-start"
			  width="260"
			  trigger="click"
			  v-model="showPopover5"
			   :popper-append-to-body="false"
			  >	
			  <!-- 单项 -->
			  <div slot="reference" class="filter-item" :class="{'isCheckedFilter':calcStyleChoose(filterMapCopy.planeMap) }" >
			  		机型
			  		<span class="el-icon-arrow-down" v-show="!showPopover5"></span>
			  		<i class="el-icon-arrow-up" v-show="showPopover5"></i>
			  </div>
			  
			  <!-- 弹窗 -->
			  <div>
				  <div class="filter-header">
					  <span>机型</span>
					   <i class="el-icon-close" @click="showPopover5 = false "></i>
				  </div>
				  <div class="filter-content">
					  <div>
						  <div class="filter-item" 
						  :class="{'checked':filterItem.checked,'disabled':filterItem.filterCount<1,'isFirstFilter':firstFilterMapStr == 'planeMap'}"
						  v-for="(filterItem,filterkey) in filterMapCopy.planeMap" 
						  :key="filterkey"
						  @click="chooseFilterItem(filterItem,'planeMap')"
						  >
							  <span class="filter-check-item">
							  	<span class="iconfont yj-icon-ok" v-show="filterItem.checked"></span>
							  	<span class="iconfont yj-icon-icon-no-checkbox" v-show="!filterItem.checked"></span>
							  	<span class="filter-check-item-name">{{filterItem.name}}</span>
							  </span>
							  <span>￥{{filterItem.minPrice}}</span>
						  </div>
						  
					  </div>
				  </div>
			  </div>
			 
			</el-popover>
			
			<!-- 清空 -->
			<el-button class="remove-all-btn" type="text" :disabled="!isShowRemoveAll" @click="removeFilterMap(filterMapCopy)">清空</el-button>
			
		</div>
		
		<!-- 排序 -->
		
		<div class="tk-filter-right">
			<div class="sort-item" :class="{'active':chooseSortKey == 'minFilterPrice-1'}" @click="chooseSortItem({code:'minFilterPrice-1',name:'价格低-高'})">
				低价优先
			</div>
			<div  class="sort-item" :class="{'active':(chooseSortKey == 'departTime-1' || !chooseSortKey)}" @click="chooseSortItem({code:'departTime-1',name:'起飞早-晚'})">
				起飞时间早-晚
			</div>
			
			
			<el-popover
			  placement="bottom"
			  width="110"
			  trigger="hover"
			  v-model="showPopoverMoreSort"
			   :popper-append-to-body="false"
			  >	
				<div slot="reference"  
				class="sort-item" 
				:class="{'active':(chooseSortKey !='minFilterPrice-1' && chooseSortKey != 'departTime-1' && chooseSortKey )}" 
				style="text-align: right;padding: 0;width: 80px;">
				   <span v-show="(chooseSortKey =='minFilterPrice-1' || chooseSortKey == 'departTime-1' || !chooseSortKey)"  >更多排序</span>
				   <span v-show="(chooseSortKey !='minFilterPrice-1' && chooseSortKey != 'departTime-1')">{{chooseSortName}}</span>
					<span class="el-icon-arrow-down" v-show="!showPopoverMoreSort"></span>
					<i class="el-icon-arrow-up" v-show="showPopoverMoreSort"></i>
				</div>
				
				<div class="sort-content">
					<div class="sort-item" 
					     :class="{'choosed':sortItem.code == chooseSortKey}"
						v-for="(sortItem,sortKey) in sortMap"
						:key="sortKey"
						v-show="!sortItem.isNoShow"
						@click="chooseSortItem(sortItem)"
					 >
					   <span v-show="sortItem.code == chooseSortKey" class="el-icon-check sort-item-checked"></span>
					   <span>{{sortItem.name}}</span>
					</div>
					
				</div>
				
			</el-popover>
		</div>
		
		  
		
		
	</div>
</template>

<script>
	import tkCommon from './../../tkCommon/tkCommon.js'
	export default{
		name:"TkFilter",
		data(){
			return{
				// 筛选数据
				straight:false, // 是否直飞
				stop:false,     // 是否经停
				
				showPopover1:false,
				showPopover2:false,
				showPopover3:false,
				showPopover4:false,
				showPopover5:false,
			
				firstFilterMapStr:"", // 第一次选中的筛选项
				filterMapCopy:{}, // 筛选数据
				isShowRemoveAll:false, // 是否显示清空按钮
				
				
				// 排序数据
				chooseSortKey:'',  // 1 升序  2 降序
				chooseSortName:'',
				showPopoverMoreSort:false, // 显示更多排序
				sortMap:{
					'departTime-1':{
						name:"起飞早-晚",
						code:"departTime-1",
						type:'departTime',
						isNoShow:true,
					},
					'departTime-2':{
						name:"起飞晚-早",
						code:"departTime-2",
						type:'departTime'
					},
					'arriveTime-1':{
						name:"到达早-晚",
						code:"arriveTime-1",
						type:'arriveTime'
					},
					'arriveTime-2':{
						name:"到达晚-早",
						code:"arriveTime-2",
						type:'arriveTime'
					},
					'flyTime-1':{
						name:"耗时短-长",
						code:"flyTime-1",
						type:'flyTime'
					},
					'flyTime-2':{
						name:"耗时长-短",
						code:"flyTime-2",
						type:'flyTime'
					},
					'minFilterPrice-1':{
						name:"价格低-高",
						code:"minFilterPrice-1",
						type:'minFilterPrice',
						isNoShow:true,
					},
					'minFilterPrice-2':{
						name:"价格高-低",
						code:"minFilterPrice-2",
						type:'minFilterPrice'
					}
				}
			}
		},
		props:{
			tkFilterMap:{
				type:[Object],
				default (){
					return {
						// 舱位map
						cabinTypeMap:{},
						// // 航司map
						airlineMap:{},
						// // 起抵时段
						depTimeMap:{},
						arrTimeMap:{},
						// // 起飞机场
						depAirportMap:{},
						// // 降落机场
						arrAirportMap:{},
						// // 计划机型
						planeMap:{},
						// 直飞 经停
						stopNumMap:{}
					}
				}
			},
			// 默认排序
			sortType:{
				type:[String,Function],
				default(){
					return 'departTime-1'
				}
			}
		},
		watch:{
			tkFilterMap:{
				immediate: true, // 立即执行
				deep:true, // 深度监听
				handler(val) {
				  this.setFilterData(val)
				},
				
			},
			// 排序类型监听
			sortType(val) {
				  this.chooseSortKey = val;
				  if(this.chooseSortKey){
				  	this.chooseSortName = this.sortMap[this.chooseSortKey]['name'];
				  }
				
			}
			
		},
		mounted() {
			// 初始化数据
			// this.setFilterData(this.tkFilterMap)
			/*
			 *  本页面存在问题： 
			 *     筛选项判断其他项，是否可以选择
			 *    
			 * 
			 * 
			 *
			 ***/ 
			
			
		},
		methods:{
			setFilterData(filterMap){
				this.filterMapCopy = this.$common.deepCopy(filterMap);
				
				// 遍历处理， 筛选条件是否为空
				let isCunFirst =  this.panDuanFilterMapIsFirst(this.filterMapCopy);
				if(isCunFirst){
					this.isShowRemoveAll = true;	
				}else{
					this.firstFilterMapStr = '';  // 清空首选项标识
				}
				
			},
			/*
			 *  选择筛选数据
			 *  确定第一次操作的项，标记为选中， 该项为基项， 同类型不满足条件可以选择，
			 * 
			 * 
			 * 
			 */ 
			chooseFilterItem(filterItem,filterTypeKey){
				
				// 第一步：筛选项Map是否存在 首选项
				let isCunFirst =  this.panDuanFilterMapIsFirst(this.filterMapCopy);
				// 如果不存在首选项
				if(!isCunFirst){
					this.firstFilterMapStr = filterTypeKey;
				}
				console.log(isCunFirst,this.firstFilterMap)
				// 可以操作选择的， 1.首选同种类型，2. filterCount 大于0的
				// if(filterItem.filterCount>0 || this.firstFilterMapStr == filterTypeKey){
					filterItem.checked =!filterItem.checked;
					console.log(filterItem)
					 // this.$forceUpdate(this.filterMapCopy);
					this.$emit('changeTkFilterMap',this.filterMapCopy)
				// }
				
			},
			
			// 判断筛选MAP中，是否有被选中的，
			panDuanFilterMapIsFirst(filterMapCopy){
			   var isCunFirst = false;
			   for(var filterKey in filterMapCopy){
				  for(var filterItemKey in filterMapCopy[filterKey]){
					  if(filterMapCopy[filterKey][filterItemKey]['checked']){
						  var isCunFirst = true;
					  }
				  }  
			   }
			  return  isCunFirst
			},
			
			// 清空筛选项  清空筛选条件  并恢复筛选航班数据状态
			removeFilterMap(filterMapCopy){
				for(var filterKey in filterMapCopy){
					 for(var filterItemKey in filterMapCopy[filterKey]){
					    filterMapCopy[filterKey][filterItemKey]['checked'] = false
					 }  
				}
				this.$emit('changeTkFilterMap',filterMapCopy)
				this.isShowRemoveAll = false;
			},
			// 选择一项 排序
			chooseSortItem(sortItem){
				this.$emit('chooseSortItem',sortItem.code)
			},
			
			
			// 计算筛选样式是否被选中
			calcStyleChoose(filterMap){
				var bool = false;
				for(var filterKey in filterMap){
					if(filterMap[filterKey]['checked']){
						bool = true
					}
				}
				return bool
			},
			
			
			
			
		}
	}
</script>

<style scoped="scoped" lang="scss">

	.tk-filter{
		width: 1200px;
		background-color: white;
		height: 60px;
		text-align: left;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 20px;
		font-family: '微软雅黑';
		.tk-filter-left{
			display: flex;
			align-items: center;
			
			.stop-item{
				display: inline-block;
				width: 60px;
				// height: 28px;
				
			}
			.stop-item.checked{
				font-weight: bolder;
				color: #1C83ED;
			}
			.filter-item{
				width: 78px;
				height: 28px;
				line-height: 28px;
				border: 1px solid #EBEEF2;
				font-size: 12px;
				font-weight: 500;
				color: #000000;
				// padding: 0 10px;
				text-align: center;
				// display: flex;
				// align-items: center;
				margin-left: 20px;
				cursor: pointer;
				border-radius: 4px;
			}
			.filter-item.isCheckedFilter{
				border-color: #409EFF;
			}
		}
		.tk-filter-right{
			display: flex;
			align-items: center;
			.sort-item{
				// height: 44px;
				// line-height: 44px;
				padding: 2px 10px;
				border-radius: 4px;
				margin-left: 20px;
				cursor: pointer;
			}
			.sort-item:hover{
				background-color: #E6F2FF;
			}
			.sort-item.active{
				font-weight: bolder;
				color: #1C83ED;
				// background: #E6F2FF;
			}
		}
		
		
		
		
	}
	
	// 弹窗样式
	.popover-content{
		display: flex;
		// justify-content: ;
		.popover-content-item{

		}
		.popover-content-item-left{
				width: 50%;
			padding-right: 15px;
			border-right: 1px solid #f7f7f7;
		}
		.popover-content-item-right{
			padding-left: 15px;
				width: 50%;
			// border-right: 1px solid #f7f7f7;
		}
	}
	
	.filter-header{
		width:100%;
		display: flex;
		height: 40px;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #f7f7f7;
		font-weight: bolder;
		
	}
	.filter-content{
		.filter-item{
			height: 40px;
			width:calc(100% + 24px);
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-left: 12px;
			padding-right: 12px;
			margin-left: -12px;
			margin-right: -12px;
			cursor: pointer;
			transition: color .25s cubic-bezier(.71,-.46,.29,1.46);
			.filter-check-item:not(.company){
				.filter-check-item-name{
					margin-left:8px ;
				}
			}
			.filter-check-item.company{
				.filter-check-item-img{
					margin-left:8px ;
				}
			}
			
		}
		.filter-item:not(.disabled):hover{
			    background-color: #e8f0fb;
			    cursor: pointer;
		}
		.filter-item.checked{
			color:#409EFF;
		} 
		.filter-item:not(.isFirstFilter).disabled{
			color: #bebebe;
		
			cursor: not-allowed;
		}
		.filter-item img{
			margin-right: 8px;
		}
	}
	
	.remove-all-btn{
		margin-left: 15px;
	}
	
	
	// 排序弹窗样式
	.sort-content{
		.sort-item{
			text-align: center;
			height: 30px;
			line-height: 30px;
			position: relative;
			cursor: pointer;
			.sort-item-checked{
				position: absolute;
				left: 2px;
				top: 8px;
			}
		}
		.sort-item.choosed{
			color: #1C83ED;
			font-weight: bolder;
		}
	}
	
	
	
</style>
