<template>
	<div>
		<!-- 七天日期 -->
		<div class="seven-day-price" v-show="!isShowMoreDate">
			<div class="day-btn" :class="{'gray-day-btn':nowDate == minWeekDate}" @click="getPreWeekDay()">
				<span class="el-icon-arrow-left"></span>
			</div>
			<div class="day-list">
				
					<div class="day-list-content" :style="calcSevenWeekPrice(translateXDistance,marginLeft)" >
						<div class="day-item " 
							 v-for="(weekItem,weekIndex) in sevenWeekPrice" 
							 :key="weekItem.date + weekIndex"
							 :class="{'choosed':chooseDate == weekItem.date}" 
							 @click="changeDate(weekItem)">
								<div class="day-item-date">{{weekItem.date | filterDate}} {{weekItem.week}}</div>
								<div class="day-item-price">
									<span v-show="weekItem.price>0">￥{{weekItem.price}}
										<span class="tag tag-lowprice" v-show="calcMinWeekPrice(weekItem,minRealPrice)">低</span>
									</span>
									<span v-show="!(weekItem.price>0)">查看</span>
								</div>
						</div>
					</div>
			</div>
			<div class="day-btn" @click="getNextWeekDay()">
				<span class="el-icon-arrow-right"></span>
			</div>
			<div class="more-date" @click="isShowMoreDate = true">
				<span class="el-icon-date icon"></span>
				<span class="text">更多日期</span>
			</div>
		</div>
		<!-- 更多日期 -->
		<div v-show="isShowMoreDate">
			<div class="calender-title">
				<i class="el-icon-date"></i>
				<span>日历</span>
				<i class="el-icon-close right" @click="isShowMoreDate = false"></i>
			</div>
			<el-calendar :hideHeader="false" v-model="currentChooseDate" >
				<template slot="dateCell" slot-scope="{date, data}">
					<p :class="{'is-selected':data.isSelected}">
						{{ data.day.split('-').slice(1).join('-') }} {{ data.isSelected ? '✔️' : ''}}
					</p>
					<p style="font-size: 12px;">
						{{ calcMinPrice(data.day,priceDateMap)}}
					</p>
				</template>
			</el-calendar>

		</div>

	</div>

</template>

<script>
	export default {
		name: "SevenDayPrice",
		data() {
			return {

				isShowMoreDate: false,
				priceDateMap: {}, //  价格日历map
				priceMapLimit: {}, // 限制最小值，最大值
				szmPriceMap: {}, // 三字码价格map

				sevenWeekPrice: [], // 七天价格数组
				currentChooseDate: '',
				
				marginLeft:-7,
				translateXDistance: 0,
				minWeekDate: "", // 当前页面显示最小7天价格
				maxWeekDate: "", // 当前页面显示最大7天价格
				nowDate:"",
				
				minRealPrice:0,// 当前七天价格中最低价
			}
		},
		emits:['changeSevenDate'],
		props: {
			// 七天价格
			weekPrice: {
				type: [Array],
				default () {
					return []
				}
			},
			// 当前选中的日期
			chooseDate: {
				type: [String],
				default () {
					return ''
				}
			},
			// 最小值
			minDay: {
				type: [String],
				default () {
					return ''
				}
			},
			// 最大值
			maxDay: {
				type: [String],
				default () {
					return ''
				}
			},
			// 机票
			dateQuery: {
				type: [Object],
				default () {
					return {

					}
				}
			},
			// 单程、往返
			isQueryTravelType: {
				type: [Number, String],
				default () {
					return 1
				}
			}
		},
		computed:{
		
		},
		watch: {
			// 仅三字码变化，查询日历价格
			dateQuery: {
				handler(val, oldVal) {
					if (val.depszm && val.arrszm) {
						// 当出发到达三字码发生变化，重置日历价格集合
						this.priceDateMap = {};
						// 执行查询操作 ， 默认查询当天
						if (this.isQueryTravelType == 1) {
							this.getDatePrice(val.depszm, val.arrszm, val.depDate)
						} else {
							this.getDatePrice(val.arrszm, val.depszm, val.arrDate)
						}

					}
				},
				deep: true,
				immediate: true, // 立即执行
			},
			chooseDate(val) {
				// if (val) {
				// 	this.currentChooseDate = new Date(val)
					
				// }
			},
			// 更多日期的选择
			currentChooseDate(val) {

				if (val) {
					let YMDdate = this.$common.YMDFormat(val);
					// this.currentChooseDate = YMDdate;
					// this.isShowMoreDate = false;
					this.emitChangeDate(YMDdate)
					this.getCurrentSevenDay(YMDdate);
					// 如果点击的日期，没有价格，查询价格日历
					if(!this.priceDateMap[YMDdate]){
						this.getDatePrice(this.dateQuery.depszm,this.dateQuery.arrszm, YMDdate);
					}
				}

			}
		},
		filters: {
			filterDate(val) {
				if (val) {
					return val.substr(5, 5)
				} else {
					return val
				}
			},
			// 处理日期价格显示
			filterPriceMapDatePrice(dateItem,priceMap){
				return dateItem.price || priceMap[dateItem.date] || 0;
				
			},

		},
		mounted() {
			//七天日期的核心点
			/**
			  *   核心点1： 显示七天价格    【七天内最低价格显示 ’低‘】
			  *   核心点2： 支持切换，左右切换 【最小、最大日期临界处理】 左右滚动动画 【不定宽度的滚动动画实现】
			  *   核心点3：  支持异步加载日历价格 【最大加载值 31天】
			  * 
			  *   【不定宽度的滚动动画实现】
			  *   1.始终保持有三个周，在dom中，
			  *   2.每次左右滚动时，滚动距离为一周的距离， 默认是7个日期格子
			  *   3.若左侧有效日期不够，则无效日期顶替，单滚动距离只有有效期的距离，有效日期格子，超出则不予处理
			  *   4.右侧同上3
			  *  
			  *   
			  * **/ 

			// 用于标记最小值移动距离
			this.nowDate = this.$common.getAppointDay(new Date(), 0);
			// 获取当前七天日期最小最大值
			let minDate = this.chooseDate || this.nowDate
			if(this.sevenWeekPrice.length == 0){
				this.getCurrentSevenDay(minDate);
			}
			
			
		},
		methods: {
			// 查询日历价格，获取当前日期31天航班价格
			getDatePrice(depszm, arrszm, depDate) {
				let nowDate = depDate ||  this.$common.getAppointDay(new Date(), 0);
				if(!depszm || !arrszm){
					return false
				}
				var queryString = {
					departAirport: depszm, // 出发机场
					arriveAirport: arrszm, // 到达机场
					departDate: nowDate, // 出发日期
					days: 31, // 查询天数
				};
				this.$conn.getConn('tkBook.queryPriceCalendar')(queryString, res => {
					var data = res.data || {};
					let priceMap = data.priceMap || {};
					// 合并日历价格
					for(var dateKey in priceMap){
						this.priceDateMap[dateKey] = priceMap[dateKey]
					}
					this.matchDatePriceMap();
					// this.$forceUpdate();
				}, (err) => {
					
				})
			},


			/**
			 *  计算匹配价格
			 * 	可影响参数：
			 *      初始化价格：sevenWeekPrice
			 * 		日历价格接口返回数据：priceDateMap
			 *      七天价格：weekPrice
			 *   
			 * 
			 * **/ 
			matchDatePriceMap(){
				
				for(var k=0;k< this.sevenWeekPrice.length;k++){
					var sevenWeekPriceItem = this.sevenWeekPrice[k] || {};
					
					// 先对七天价格处理
					for(var m=0;m<this.weekPrice.length;m++){
						var weekPriceItem = this.weekPrice[m];
						if(weekPriceItem.date == sevenWeekPriceItem.date ){
							for(var key in sevenWeekPriceItem){
								sevenWeekPriceItem[key] = weekPriceItem[key];
							}
							break;
						}
					}
					
					// 如果价格为0，则对通过【日历价格】进行价格合并处理，
					if(sevenWeekPriceItem.price== 0){
						if(this.priceDateMap[sevenWeekPriceItem.date]){
							for(var key in sevenWeekPriceItem){
								sevenWeekPriceItem[key] = this.priceDateMap[sevenWeekPriceItem.date][key];
							}
							
						}
					}
					
					this.sevenWeekPrice[k] = sevenWeekPriceItem;
					
				
				}
				this.minRealPrice = this.getMinPrice(this.$common.deepCopy(this.sevenWeekPrice));
			},
			// 计算当前周最低价
			getMinPrice(sevenWeekPrice){
				let price = 0;
				let sortArr = sevenWeekPrice.sort((a,b)=>{
						if(a.price < b.price){
							return -1
						}else if(a.price > b.price){
							return 1
						}else {
							return 0
						}
				}) || [];
				
				price = sortArr[0]?sortArr[0]['price']:0 || 0;
				return price
			},
			// 计算当前周最低价
			calcMinWeekPrice(weekItem,minRealPrice){
				let bool = false;
				
				if(minRealPrice == weekItem['price'] && weekItem['price']!=0){
					bool = true;
				}
				return bool;
			},
			
			
			// 七天价格修改日期
			changeDate(dayItem) {
				this.emitChangeDate(dayItem.date)
			},
			// 触发发送
			emitChangeDate(date) {
				if(date<this.minDay || date>this.maxDay){
					this.$alert('根据贵司要求，日期的选择范围为:'+ `【${this.minDay}】-【${this.maxDay}】`)
					
					return
				}
				this.isShowMoreDate = false;
				this.$emit('changeSevenDate', date)
			},
			// 异步匹配最低价格
			calcMinPrice(day, priceDayMap) {
				var price = 0;
				if (priceDayMap[day]) {
					price = priceDayMap[day]['price'];
				}

				if (price > 0) {
					return `¥` + price
				}
				return ''
			},
			// 移动七天日期
			calcSevenWeekPrice(translateXDistance,marginLeft) {
				var styleObj = {};
				// var marginLeftDistance = - 7 - translateXDistance ;
				styleObj['marginLeft'] = marginLeft * 135 + 'px';
				styleObj['transform'] = "translateX(" + translateXDistance * 135 + "px)";
				return styleObj
			},
			 /**
			  * 获取一周时间
			  * 入参：一周最小日期
			  * 返回： 一周日期
			  * 
			  * **/ 
			 getOneWeek(minDate){
				var weekArr = [],
					maxDiff = 7;
				minDate = minDate || this.$common.getAppointDay(new Date(), 0);
				
				for(var k=0;k<maxDiff;k++){
					let date = this.$common.getAppointDay(new Date(minDate), k);
					let dayObjItem = {
						price: 0,
						date: date,
						week: this.$common.getWeekZhou(date),
					}
					weekArr.push(dayObjItem)
				}
				return weekArr
			 },
			 /**
			  *  根据当前已选择日期获取，当前的三周日期
			  *  入参： 已选择日期
			  *  响应：三周日期数组
			  * 
			  *     先处理【当前周】显示的数组，
			  *     再处理其他两周数据
			  * 	【上一周和下一周】 或者【下两周】
			  * 
			  * **/ 
			 
			 
			getCurrentSevenDay(dapDate,type){
				var maxDiff = 7;
				let zongWeekArr = [];
				let curWeekArr = [];
				let minWeekDate = ''; // 是否存在上一周数据
				let nowDate = this.$common.getAppointDay(new Date(), 0);
				if(dapDate){
					let dayDiff = this.$common.getDiffDay(nowDate,dapDate);
					if(dayDiff<4){
						minWeekDate = nowDate;
					}else{
						if(this.sevenWeekPrice.length == 0){
							minWeekDate = this.$common.getAppointDay(new Date(dapDate),-3);
						}else{
							minWeekDate = dapDate;
						}
						
					}
				}else{
					minWeekDate = nowDate;
				}
				curWeekArr = this.getOneWeek(minWeekDate);
				
				// 组合成3个月的本周七天价格
				zongWeekArr = zongWeekArr.concat(curWeekArr).concat(curWeekArr).concat(curWeekArr)
				this.sevenWeekPrice = zongWeekArr || [];
				// 计算移动距离
				if(type == 'pre'){
					this.translateXDistance += 7;
					this.marginLeft = -7 - this.translateXDistance;
				}else if(type == 'next'){
					this.translateXDistance -= 7;
					this.marginLeft = -7 - this.translateXDistance;
				}else{
					this.translateXDistance = 0;
					this.marginLeft = -7 - this.translateXDistance;
				}
				
				this.matchDatePriceMap();
			},
			/**
			 *  获取上周日期数据
			 *  判断是否可以继续返回
			 * 
			 * **/ 
			getPreWeekDay() {
				
				// 当前存在日期的最小日期
				let nowDate = this.$common.getAppointDay(new Date(), 0);
				let minWeekDate = nowDate;
				let minDateObj = this.sevenWeekPrice[0];
				if(minDateObj&&minDateObj.date){
					minWeekDate = minDateObj.date
				}
				
				// 如果最小日期和 今天有正差值，则仍然可以向左移动，否则不可向左移动
				let dayDiff = this.$common.getDiffDay(nowDate, minWeekDate);
				// 当diff>-7时，满足可移动条件
				if(dayDiff>0){
					let maxDiff = dayDiff>7?7:dayDiff;
					let preMinWeekDate  = this.$common.getAppointDay(new Date(minWeekDate),-maxDiff);
					this.getCurrentSevenDay(preMinWeekDate,'pre')	
				}
			},
			// 获取下周数据   日期从小到大
			getNextWeekDay() {
					// 获取最后一个数据
					let minWeekObj = this.sevenWeekPrice[0];
					let minWeekDate = minWeekObj.date;
					this.getCurrentSevenDay(this.$common.getAppointDay(new Date(minWeekDate),7),'next')	
			}


		}
	}
</script>

<style scoped="scoped" lang="scss">
	// 七天日期
	.seven-day-price {
		display: flex;
		background-color: white;
		align-items: center;

		.day-btn {
			width: 64px;
			font-size: 20px;
			color: #808080;
			font-weight: bolder;
			display: inline-block;
			height: 74px;
			line-height: 74px;
		}
		.gray-day-btn{
			color: #f0f0f0;
		}

		.day-btn:not(.gray-day-btn):hover {

			color: #1C83ED;
			cursor: pointer;
		}

		// 滚动列表
		.day-list {
			width: 945px;
			overflow-x: auto;
		}

		.day-list::-webkit-scrollbar {
			display: none;
		}

		.day-list-content {
			min-width: 945px;
			width: 2835px;
			white-space: nowrap;
			-webkit-transition: -webkit-transform .5s ease-in-out;
			transition: -webkit-transform .5s ease-in-out;
			transition: transform .5s ease-in-out;
			transition: transform .5s ease-in-out, -webkit-transform .5s ease-in-out;



			// display: flex;
			.day-item {
				width: 135px;
				
				padding: 15px 0;
				display: inline-block;

				.day-item-date {
					font-size: 12px;
					font-family:'微软雅黑';
					font-weight: 500;
					color: #424242;
					line-height: 19px;
				}

				.day-item-price {
					font-size: 14px;
					font-family:'微软雅黑';
					font-weight: 500;
					color: #1C83ED;
					line-height: 19px;
				}
			}

			.day-item.choosed {
				background: #1C83ED;
				color: white;

				.day-item-date {
					color: white;
				}

				.day-item-price {
					color: white;
				}
			}

			.day-item:hover {
				cursor: pointer;
			}

			.day-item:not(.choosed):hover {
				color: #1C83ED;

				.day-item-date {
					color: #1C83ED;
				}

				.day-item-price {
					color: #1C83ED;
				}
			}
		}
	}

	.calender-title {
		padding: 10px 20px 0 20px;
		text-align: left;
		font-size: 20px;
		background-color: white;
		font-weight: 600px;
	}

	.right {
		float: right;
	}

	// 更多日期
	.more-date {
		width: 120px;
		display: flex;
		align-items: center;
		justify-content: space-around;
		padding: 0 15px;
		font-weight: 500;

		.icon {
			font-size: 22px;
		}

		.text {
			font-size: 14px;
			margin-left: 5px;
		}
	}

	.more-date:hover {
		color: #1989FA;
		cursor: pointer;
	}

	.is-selected {
		color: #1989FA;
		font-weight: bolder;
	}

	/deep/ .el-calendar-table .el-calendar-day {
		height: 48px;
		padding: 8px;
	}

	/deep/ .el-calendar-table thead th {
		padding: 0 0 10px 0;
	}
	
	
	.tag-lowprice {
		display: inline-block;
		background-color: #f60;
		color: #fff;
		height: 14px;
		line-height: 14px;
		border-radius: 1px;
		width: 14px;
		-webkit-box-sizing: content-box;
		-moz-box-sizing: content-box;
		box-sizing: content-box;
		// margin: -2px 0 0 0px;
		font-size: 12px;
		text-align: center;
	}
	
</style>
