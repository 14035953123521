<template>
	<div class="tk-query-date">
		<div  v-show="travelType == 2">
			<el-date-picker
				:editable="false"
			     v-model="dateRange"
			     type="daterange"
				 range-separator="—"
			     start-placeholder="开始日期"
			     end-placeholder="结束日期"
				 value-format="yyyy-MM-dd"
				 :picker-options="pickerOptions(minDay,maxDay)"
				 @change="onPickDate"
				>
			   </el-date-picker>
		</div>
		<div v-show="travelType == 1">
			<el-date-picker
				:editable="false"
			   v-model="date1"
			   align="right"
			   type="date"
			   placeholder="选择日期"
			   :picker-options="pickerOptions(minDay,maxDay)"
				value-format="yyyy-MM-dd"
				@change="onPickDate"
			 >
						   
			   </el-date-picker>
		</div>
		   
		
		   
	</div>
</template>

<script>
	export default{
		data(){
			return{
				
		
				dateRange:[],
				date1:"", // 一个
			}
			
		},
		props:{
			travelType:{
				type:[String,Number],
				default (){
					return 1
				}
			},
			depDate:{
				type:[String],
				default (){
					return ''
				}
			},
			arrDate:{
				type:[String],
				default (){
					return ''
				}
			},
			minDay:{
				type:[String],
				default (){
					return ''
				}
			},
			maxDay:{
				type:[String],
				default (){
					return ''
				}
			},
		},
		watch:{
			twoRangeDate(val){
				this.dateRange = val; 
			},
			oneRangeDate(val){
				this.date1 = val; 
			}
		},
		computed:{
			twoRangeDate(){
				let arr = [];
				if(this.depDate){
					arr.push(this.depDate)
				};
				if(this.arrDate){
					arr.push(this.arrDate)
				}
				return arr
			},
			oneRangeDate(){
				let str = '';
				if(this.depDate){
					str = this.depDate
				};
				return str
			}
		},
		mounted(){
			setTimeout(()=>{
				this.dateRange = this.twoRangeDate;
				this.date1 = this.oneRangeDate;
			},0)
		},
	
		methods:{
			pickerOptions(minDay,maxDay){
				let minTime = this.$common.getStartDateTimeStamp(minDay);
				let maxTime = this.$common.getStartDateTimeStamp(maxDay);
				return {
						disabledDate(time) {
							return time.getTime() < minTime  || time.getTime() > maxTime;
						},
					}
			},
			
			onPickDate(date){
				
				this.$emit('chooseDate',date)
			}
		}
		
	}
</script>

<style scoped="scoped" lang="scss">
		.tk-query-date{
			display: inline-block;
		}
	
		/deep/ .el-input--small{
			font-size: 15px;
		}
		/deep/ .el-range-editor--small{
			font-size: 15px;
		}
		/deep/ .el-input--small .el-input__inner{
			height: 53px !important;
			border-radius:0 ;
			font-size: 15px;
			color: #000000;
		}
		
	
		/deep/ .el-range-editor--small .el-range-input{
			font-size: 15px;
		}
		/deep/  .el-input__inner{
			height: 53px !important;
			font-size: 15px;
			color: #000000;
			border-radius:0 ;
		}
		/deep/ .el-range-editor--small .el-range-separator{
			height: 53px;
			line-height: 53px;
			padding: 0;
			color:#999999;
		}
	
</style>
