<template>
	<div>
		<router-view />
	</div>
</template>

<script>
	export default{
		name:"Tk",
		data(){
			return{
				
			}
		}
	}
</script>

<style>
</style>
