<template>
	<div class="tk-book-rule">
		<!--机票预订须知-->
		
		<div class="book-rule-content">
			<h4 style="">关于民航乘客携带锂电池以及危险物品乘机的限制公告</h4>
			<h5 class="font16 black marginTop30">一、锂电池携带规定</h5>
			<p class="font12 black line-height24">旅客为个人自用内含锂电池的便携式电子装置 (照相机、手机、手提电脑、便携式摄像 机等)可作为手提行李携带登机，并且锂电池的额定能量值不得超过100Wh（瓦特小时）。超过100Wh但不超过160Wh的，经航空公司批准后可以装在交运行李或手提行李中的设备上。超过160Wh的锂电池严禁携带。</p>
			<p class="font12 black line-height24">
				便携式电子装置的备用电池必须单个做好保护以防短路（放入原零售包装或以其他 方式将电极绝缘，如在暴露的电极上贴胶带，或将每个电池放入单独的塑料袋或保护盒 当中），并且仅能在手提行李中携带。经航空公司批准的100 -160Wh的备用锂电池只能携带两个。旅客携带锂电池驱动的轮椅或其他类似的代步工具和旅客为医疗用途携带的、内含锂电池的便携式医疗电子装置的，必须依照相关规定和要求携带并经航空公司批准。旅客可提前与航空公司咨询以便获得帮助。
			</p>
			<p class="font12 black line-height24">按照中国民航局的规定，携带乘机的锂电池必须符合相关的安全要求，建议旅客从正规渠道购买正规厂家生产的锂电池，以免给自己或他人造成伤害。</p>
			<p class="font12 black line-height24">"充电宝"携带规定</p>
			<p class="font12 black line-height24">《关于民航旅客携带"充电宝"乘机规定的公告》</p>
			<p class="font12 black line-height24">充电宝是指主要功能用于给手机等电子设备提供外部电源的锂电池移动电源。根据现行有效国际民航组织《危险物品安全航空运输技术细则》和 《中国民用航空危险品运输管理规定》，旅客携带充电宝乘机应遵守以下规定：</p>
			<p class="font12 black line-height24">充电宝必须是旅客个人自用携带。</p>
			<p class="font12 black line-height24">充电宝只能在手提行李中携带或随身携带，严禁在托运行李中携带。</p>
			<p class="font12 black line-height24">充电宝额定能量不超过100Wh,无需航空公司批准；额定能量超过100Wh但不超过160Wh，经航空公司批准后方可携带，但每名旅客不得携带超过两个充电宝。</p>
			<p class="font12 black line-height24">严禁携带额定能量超过160Wh的充电宝；严禁携带未标明额定能量同时也未能通过标注的其他参数计算得出额定能量的充电宝。</p>
			<p class="font12 black line-height24">不得在飞行过程中使用充电宝给电子设备充电。对于有启动开关的充电宝，在飞行过程中应始终关闭充电宝。</p>
			<p class="font12 black line-height24">锂电池、充电宝额定能量的判定方法</p>
			<p class="font12 black line-height24">若锂电池、充电宝上没有直接标注额定能量Wh（瓦特小时），则锂电池、充电宝额定能量可按照以下方式进行换算:</p>
			<p class="font12 black line-height24">如果已知锂电池、充电宝的标称电压(V )和标称容量(Ah)，可以通过计算得到额定能量的数值：</p>
			<p class="font12 black line-height24">Wh= V x Ah</p>
			<p class="font12 black line-height24">标称电压和标称容量通常标记在锂电池、充电宝上。</p>
			<p class="font12 black line-height24">如果锂电池、充电宝上只标记有毫安时(mAh)，可将该数值除以1000得到安培小时(Ah)。</p>
			<p class="font12 black line-height24">例如：锂电池、充电宝标称电压为3.7V，标称容量为760 mAh ，其额定能量为：</p>
			<p class="font12 black line-height24">760 mAh ÷ 1000 = 0.76Ah</p>
			<p class="font12 black line-height24">3.7V×0.76Ah=2.9Wh</p>
			<h5 class="font16 black marginTop30">二、不能随身携带，但可托运的物品</h5>
			<p class="font12 black line-height24">除管制刀具以外的如水果刀、剃须刀等生活用刀、手术刀等专业刀具、化妆品，如来不及托运，可以办理相关手续后，在规定时间内由安检部门代为保管。（列图）</p>
			<h5 class="font16 black marginTop30">三、严禁携带及托运的物品</h5>
			<p class="font12 black line-height24">您乘坐飞机时禁止： 枪支(含各种仿真玩具枪、微型发射器及各种类型的攻击性武器)、弹药、军械、警械、爆炸物品、易燃易爆物品、剧毒物品、放射性物品、腐蚀性物品、危险溶液及其他禁运物品带上飞机或夹在行李、货物中托运。凡携带或夹带上述物品的一经查出，立即移交公安机关处理。</p>
			<h5 class="font16 black marginTop30">四、限制随身携带的液态物品的种类</h5>
			<p class="font12 black line-height24">液体饮品：如矿泉水、茶水、碳酸饮料、牛奶、酸奶、果汁等；</p>
			<p class="font12 black line-height24">洗漱化妆用品：如牙膏、洗发水、沐浴露、润肤露、剃须泡沫；</p>
			<p class="font12 black line-height24">凝胶用品：定型水、啫喱水等；</p>
			<p class="font12 black line-height24">液态食品：甜面酱、瓶装罐装罐头等食品；</p>
			<p class="font12 black line-height24">气雾剂：喷雾液</p>
			<p class="font12 black line-height24">不能通过安全检查的液态物品的处置办法</p>
			<p class="font12 black line-height24">药品：如眼药水、口服或外用药液、喷剂等；</p>
			<p class="font12 black line-height24">如果您携带了不符合安全要求的液态或凝胶物品，这些物品及容器只能弃置。安检部门 不设立保管或储存服务。请将液体、凝胶及喷雾类物品放在托运行李内，以便顺利通过安全检查。</p>
		</div>
	</div>	
</template>
<script>
	export default{
		data(){
			return{
				
			}
		},
		methods:{
			
		},
		
	}
</script>
<style scoped>

	.book-rule-content{
		text-align: left;
	}
	.font16{
		font-size: 16px;
	} 
	.black{
		color: #333;
	} 
	.marginTop30{
		margin-top: 30px;
	}
	.font12{
		font-size: 12px;
	}
	.line-height24{
		line-height: 24px;
	}
</style>
