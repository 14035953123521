<template>
	<div class="TkTravelType">
		  <el-select 
				style="height: 53px !important;" 
				v-model="currentType" 
				@change="changeTravelType" 
				placeholder="请选择"
				:disabled="isGongAndCcsqd"
				
				
				>
		    <el-option
		      v-for="item in options"
		      :key="item.value"
		      :label="item.label"
		      :value="item.value">
		    </el-option>
		  </el-select>
		<!-- <el-radio-group v-model="currentType" @change="changeTravelType">
		    <el-radio-button label="1">单程</el-radio-button>
		    <el-radio-button label="2">往返</el-radio-button>
		  </el-radio-group> -->
	</div>
</template>

<script>
	export default{
		name:"TkTravelType",
		data(){
			return {
				options:[{
				  value: '1',
				  label: '单程'
				}, {
				  value: '2',
				  label: '往返'
				}],
				currentType:'1',
			}
			
		},
		mounted(){
			this.currentType = this.travelType + '';
		},
		props:{
			travelType:{
				type:[Number , String],
				default: '1'
			},
			isGongAndCcsqd:{
				type:[Boolean],
				default: false
			}
		},
		watch:{
			travelType(val){
				this.currentType = val + '';
			}
		},
		methods:{
			changeTravelType(val){
				this.$emit('changeTravelType',val)
				
			}
		}
		
		
	}
</script>

<style scoped="scoped">
	/deep/ .el-input--small .el-input__inner{
		height: 53px !important;
		line-height: 53px;
		color: #000000;
		font-size: 15px;
		border-radius:0px;
	}
	.TkTravelType{
		display: inline-block;
		width: 89px;
		
		
	}
</style>
