<template>
	<div>
		<el-card style="margin-top: 20px;">
			<div class="pricedetail" v-show='psgCount.adultCount>0'>
				<div class="price-line">成人</div>
				<div class="price-line">
					票价
					<span class="right" v-show='ticketPrice.h>0'>¥ ({{ticketPrice.q}}+{{ticketPrice.h}})/人 *
						{{psgCount.adultCount}}人</span>
					<span class="right" v-show='ticketPrice.h==0'>¥ {{ticketPrice.q}}/人 *
						{{psgCount.adultCount}}人</span>
				</div>
				<div class="price-line">
					机场建设费
					<span class="right">¥{{airportFee}}/人 * {{psgCount.adultCount}}人</span>
				</div>
				<div class="price-line">
					燃油税
					<span class="right" v-show='taxFee!=0'>¥{{taxFee}}/人 * {{psgCount.adultCount}}人</span>
					<span class="right" v-show='taxFee==0'>免费</span>
				</div>
				<div class="price-line">
					服务费
					<span class="right">¥{{serviceFee}}/人 * {{psgCount.adultCount}}人</span>
				</div>
				<div class="price-line" v-show='selectedBxCount>0'>
					保险
					<span class="right" v-show='bxFee!=0'>¥{{bxFee}}/人 * {{psgCount.adultCount}}人</span>
					<span class="right" v-show='bxFee==0'>免费</span>
				</div>
				<div class="routeLine"></div>
				<div class="total-price">
					<span class="ch">成人总计:</span>
					<span class="num">¥{{adultTotalPrice}}</span>
				</div>
			</div>
			<div class="pricedetail" v-show='psgCount.childCount>0'>
				<div class="price-line">儿童</div>
				<div class="price-line">
					机票价
					<span class="right" v-show='ticketPrice.h>0'>¥ ({{childTicketPrice.q}}+{{childTicketPrice.h}})/人 *
						{{psgCount.childCount}}人</span>
					<span class="right" v-show='ticketPrice.h==0'>¥ {{childTicketPrice.q}}/人 *
						{{psgCount.childCount}}人</span>
				</div>
				<div class="price-line">
					机场建设费
					<span class="right">免费</span>
				</div>
				<div class="price-line">
					燃油税
					<span class="right" v-show='taxFee/2!=0'>¥{{taxFee/2}}/人 * {{psgCount.childCount}}人</span>
					<span class="right" v-show='taxFee/2==0'>免费</span>
				</div>
				<div class="price-line">
					服务费
					<span class="right">¥{{serviceFee}}/人 * {{psgCount.childCount}}人</span>
				</div>
				<div class="price-line" v-show='selectedBxCount>0'>
					保险
					<span class="right" v-show='bxFee!=0'>¥{{bxFee}}/人 * {{psgCount.childCount}}人</span>
					<span class="right" v-show='bxFee==0'>免费</span>
				</div>
				<div class="routeLine"></div>
				<div class="total-price">
					<span class="ch">儿童总计:</span>
					<span
						class="num">¥{{childTotalPrice}}</span>
				</div>
			</div>
			<div class="pricedetail" v-show='psgCount.minChildCount>0'>
				<div class="price-line">婴儿</div>
				<div class="price-line">
					机票价
					<span class="right" v-show='minChildTicketPrice.h>0'>¥
						({{minChildTicketPrice.q}}+{{minChildTicketPrice.h}})/人
						* {{psgCount.minChildCount}}人</span>
					<span class="right" v-show='minChildTicketPrice.h==0'>¥ {{minChildTicketPrice.q}}/人 *
						{{psgCount.minChildCount}}人</span>
				</div>
				<div class="price-line">
					机场建设费
					<span class="right">免费</span>
				</div>
				<div class="price-line">
					燃油税
					<span class="right" v-show='taxFee!=0'>¥{{taxFee}}/人 * {{psgCount.minChildCount}}人</span>
					<span class="right" v-show='taxFee==0'>免费</span>
				</div>
				<div class="price-line">
					服务费
					<span class="right">¥{{serviceFee}}/人 * {{psgCount.minChildCount}}人</span>
				</div>
				<div class="price-line" v-show='selectedBxCount>0'>
					保险
					<span class="right" v-show='bxFee!=0'>¥{{bxFee}}/人 * {{psgCount.minChildCount}}人</span>
					<span class="right" v-show='bxFee==0'>免费</span>
				</div>
				<div class="routeLine"></div>
				<div class="total-price">
					<span class="ch">婴儿总计:</span>
					<span
						class="num">¥{{minChildTotalPrice}}</span>
				</div>
			</div>

		</el-card>
	</div>
</template>

<script>
	export default {
		name: 'TkFlightPrice',
		data() {
			return {
				selectedBxCount: 0,
				bxListCopy: [],
				cxrListCopy:[],
				bxFee: 0,
			}
		},
		props: {
			chooseFlight: {
				type: [Object],
				default () {
					return {

					}
				}
			},
			chooseFlightBack: {
				type: [Object],
				default () {
					return {

					}
				}
			},
			cxrList: {
				type: [Array],
				default () {
					return []
				}
			},
			bxList: {
				type: [Array],
				default () {
					return []
				}
			}
		},
		watch: {
			bxList: {
				immediate: true, // 立即执行
				deep: true,
				handler(val, oldVal) {
					// 处理出行人数据
					this.bxListCopy = this.$common.deepCopy(val);
					this.bxFee = this.calcBxFee();
				},
			},
			cxrList:{
				immediate: true, // 立即执行
				deep: true,
				handler(val, oldVal) {
					// 处理出行人数据
					this.cxrListCopy = this.$common.deepCopy(val);
					
				},
			}
		},
		methods: {
			calcBxFee() {
				this.selectedBxCount = 0;
				var res = 0;
				var arr = this.bxList;
				for (var i = 0; i < arr.length; i++) {
					if (arr[i].checked) {
						this.selectedBxCount += 1;
						res += arr[i].saleprice;
					}
				}
				return res;

			}
		},
		computed: {
			//去程航班
			qAir() {
				return this.chooseFlight;
			},
			//返程航班
			hAir() {
				return this.chooseFlightBack || {};
			},

			//各种类型出行人的数量
			qCabin() {
				return this.qAir.chooseCabin || {}
			},
			hCabin() {
				return this.hAir.chooseCabin || {}
			},
			psgCount() {
				var adultCount = 0,
					childCount = 0,
					minChildCount = 0;
				var cxr;
				for (var i = 0; i < this.cxrListCopy.length; i++) {
					cxr = this.cxrListCopy[i];
					if (cxr.psgType == 1) {
						adultCount++;
					} else if (cxr.psgType == 2) {
						childCount++;
					}
					// 婴儿
					if (cxr.children && cxr.children.length > 0) {
						minChildCount++;
					}
				}
				return {
					adultCount: adultCount,
					childCount: childCount,
					minChildCount: minChildCount
				}
			},
			//机票价(成人单价)
			ticketPrice() {
				return {
					q: this.qCabin.salePrice,
					h: this.hCabin.salePrice || 0,
				}
			},
			//全价票价格
			allPrice() {
				var qCabinType = this.qCabin.cabinType,
					hCabinType = this.hCabin ? this.hCabin.cabinType : null;
				    qCabinType = qCabinType.toLowerCase();
				if (!(qCabinType == 'c' || qCabinType == 'f')) {
					qCabinType = 'y';
				}
				var qAttr = qCabinType + 'Price',
					qPrice, hAttr, hPrice = 0;
				qPrice = this.qAir[qAttr];
				if (hCabinType) {
					hCabinType = hCabinType.toLowerCase();
					if (!(hCabinType == 'c' || hCabinType == 'f')) {
						hCabinType = 'y';
					}
					hAttr = hCabinType + 'Price';
					hPrice = this.hAir[hAttr];
				}
				return {
					q: qPrice,
					h: hPrice
				}
			},
			//机票价(儿童单价)
			childTicketPrice() {
				return {
					q: this.allPrice.q / 2,
					h: this.allPrice.h / 2,
				}
			},
			//机票价(婴儿单价)
			minChildTicketPrice() {
				return {
					q: this.allPrice.q * 0.1,
					h: this.allPrice.h * 0.1,
				}
			},
			//机场建设费(往返：每人单价)
			airportFee() {
                
				var qAirportFee = this.qAir.airportFee || 0;
                console.log(this.qAir.airportFee)
				var HirportFee = this.hAir.airportFee || 0;
				return qAirportFee + HirportFee;
			},
			//燃油税(往返：每人单价)
			taxFee() {
				var qTax = this.qAir.tax || 0;
				var hTax = this.hAir.tax || 0;
				return qTax + hTax;
			},
			//服务费(往返：每人单价)
			serviceFee() {
				var qServiceFee = this.qCabin.serviceFee || 0;
				var hServiceFee = this.hCabin.serviceFee || 0;
				return qServiceFee + hServiceFee;
			},
			//保险(每人单价)
			// bxFee(){
			// 	this.selectedBxCount = 0;
			// 	var res = 0;
			// 	var arr = this.bxList;
			// 	for(var i=0;i<arr.length;i++){
			// 		if(arr[i].checked){
			// 			this.selectedBxCount+=1;
			// 			res+= arr[i].saleprice;
			// 		}
			// 	}
			// 	return res;
			// },
			//成人机建、税费、服务费、保险费的和
			adultPrice() {
				return this.airportFee + this.taxFee + this.serviceFee + this.bxFee;
			},
			//儿童机建、税费、服务费、保险费的和
			childPrice() {
				return this.airportFee * 0 + this.taxFee / 2 + this.serviceFee + this.bxFee;
			},
			//婴儿机建、税费、服务费、保险费的和
			minChildPrice() {
				return this.airportFee * 0 + this.taxFee * 0 + this.serviceFee + this.bxFee;
			},
			
			
			// 成人总计：
			adultTotalPrice(){
				return (this.adultPrice*100 + this.ticketPrice.q*100 + this.ticketPrice.h*100) * this.psgCount.adultCount/100
			},
			// 儿童总计：
			childTotalPrice(){
				return (this.childPrice*100 + this.childTicketPrice.q*100 + this.childTicketPrice.h*100)*this.psgCount.childCount/100
			},
			// 婴儿总计：
			minChildTotalPrice(){
				return (this.minChildPrice*100  + this.minChildTicketPrice.q*100  + this.minChildTicketPrice.h*100 )*this.psgCount.minChildCount/100
			}
			

			
		}
	}
</script>

<style scoped="scoped" lang="scss">
	// /deep/ .el-card__header{
	// 	padding: 0 20px;
	// 	background:#006BB9 ;
	// }
	.pricedetail {
		background: #fff;
		font-size: 12px;
		// padding: 20px;
		text-align: left;
	}
	.right{
		float: right;
	}
	.price-line {
		line-height: 25px;
		height: 25px;
		clear: both;
	}

	.pricedetail .routeLine {
		padding: 10px 0;
	}

	.total-price {
		text-align: right;
		padding-top: 16px;
		padding-bottom: 10px;
		font-family: '微软雅黑';
		font-weight: bold;
		color: rgba(34, 51, 68, 1);
	}

	.total-price .ch {
		font-size: 12px;
	}

	.total-price .num {
		font-size: 24px;
	}
</style>
