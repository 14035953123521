<template>
	<div class="tri-main">
		<el-card 
		 
		>
			<h1 slot="header" class="text-left ">
				预订信息
				<span class="tk-book-psg-tips">
					<span class="iconfont yj-icon-tixing"></span>
					预订12周岁的儿童票/婴儿票,请联系客服
				</span>
			</h1>
			<el-form ref="cxrListValidateForm">
				<div class="tri-box  padTop20" v-for="(item, index) in cxrList" :key="index">
					<!-- 乘客 -->
					
						<div  class="tai-box-tit flex justify-content-space-between marBtm30">
							<span class="psg-number">{{'乘客' +(index+1)}}
								<span>[{{item.cxrName}}]</span>
							</span>
							<span  class="removeColor pointer" @click="removePassenger(index)">{{item.psgType=='1'?'移除旅客':'移除儿童'}}</span>
						</div>

					<div class="form-item-div">
						<el-form-item
							label-position="top"
							label="乘客类型"
							>
							<el-select  
								class="font15 width180 borderStyle" 
								v-model="item.psgType" 
								@change="updateCxrListType(item.psgType, item,index)" 
								placeholder="请选择">
							  <el-option
							    v-for="itemp in passengerTypeList"
							    :key="itemp.id"
							    :label="itemp.text"
							    :value="itemp.id">
							  </el-option>
							</el-select> 
							
						</el-form-item>
						
						<el-form-item
							label-position="top"
							label="姓名(通过员工控件选择时,不可修改姓名)"
							v-show="item.useCardType == 1"
						>
							<el-input  
								class="font15 width465 borderStyle" 
								placeholder="请输入乘客姓名" 
								v-model="item.cxrName"
								:disabled="item.cxrType==1"
								@input="changeCxrName(item.cxrName,item,index)"
								></el-input> 
						</el-form-item>
						
						<el-form-item
							label-position="top"
							label="英文名(通过员工控件选择时,不可修改姓名)"
							v-show="item.useCardType != 1"
						>
							<el-input  
								class="font15 width465 borderStyle" 
								placeholder="请输入乘客英文姓名" 
								v-model="item.engName"
								:disabled="item.cxrType==1"
								@input="changeEngName(item.engName,item,index)"
								></el-input> 
						</el-form-item>
					</div>
					
					<div class="form-item-div">
						<el-form-item
							label-position="top"
							label="证件类型"
						>
							<el-select class="font15 width180 borderStyle"  v-model="item.useCardType" placeholder="请选择证件类型"  @change="changeUseCardType(item.useCardType,item,index)">
							  <el-option
							    v-for="itemc in idTypeList"
							    :key="itemc.id"
							    :label="itemc.text"
							    :value="itemc.id">
							  </el-option>
							</el-select>
						</el-form-item>
						<el-form-item
							label-position="top"
							label="证件号码"
						>	
							<el-input  
								v-for="(cardItem,cardIndex) in item.idcardList" 
								:key="cardItem.idType + cardIndex " 
								v-show="cardItem.idType == item.useCardType" 
								class="font15 width465 borderStyle" 
								placeholder="请输入证件号码" 
								v-model="cardItem.idNo"
								@input="changeIdcardList(cardItem.idNo,item,index,cardIndex,'idNo')"
								></el-input>  
						</el-form-item>
					</div>
					
					<div class="form-item-div" v-if="item.useCardType == 2">
						<el-form-item
							label-position="top"
							label="出生日期"
						>
							<el-date-picker
							    prefix-icon="none"
								class="font15 width180"
								align="right"
							    v-model="item.born"
							    type="date"
								@change="chooseStartDate(item.born ,item,index)"
								placeholder="请选择出生日期"
								>
							    
							</el-date-picker>
						</el-form-item>
						
						<div class="width465 flex justify-content-space-between">
							<el-form-item
								v-for="(cardItem,cardIndex) in item.idcardList"
								:key="cardItem.idType + cardIndex " 
								v-show="cardItem.idType == item.useCardType" 
								label-position="top"
								label="证件有效期"
							>
								<el-date-picker
									prefix-icon="none"
									class="font15 width215"
									placeholder="请选择证件有效期"
								    v-model="cardItem.validDate"
								    type="date"
									@change="changeIdcardList(cardItem.validDate,item,index,cardIndex,'validDate')">
								    
								</el-date-picker>
							</el-form-item>
							<el-form-item
								v-for="(cardItem,cardIndex) in item.idcardList"
								:key="cardItem.idType + 'placeOfIssue' " 
								v-show="cardItem.idType == item.useCardType" 
								label-position="top"
								label="证件签发地"
							>
								<el-select class="font15 width215"  
								v-model="cardItem.placeOfIssue" 
								filterable
								@change="changeIdcardList(cardItem.placeOfIssue,item,index,cardIndex,'placeOfIssue')" 
								placeholder="请选择签发地">
									<el-option
										v-for="itemgj in countryList"
										:key="itemgj.id"
										:label="itemgj.cName"
										:value="itemgj.id">
										<span style="float: left">{{ itemgj.cName }}</span>
										<span style="float: right; color: #8492a6; font-size: 13px">{{ itemgj.by3 }}</span>
									</el-option>
								</el-select>  
							
							</el-form-item>
							
						</div>
						
						
					</div>
					
					<!-- 国籍 -->
					<div class="form-item-div" v-if="item.useCardType == 2">
						<el-form-item
							label-position="top"
							label="国籍"
						>
							<el-select class="font15 width215"  
							v-model="item.nation" 
							filterable 
							@change="changeNation(item.nation,item,index,'nation')" 
							placeholder="国籍">
								<el-option
									v-for="itemgj in countryList"
									:key="itemgj.id"
									:label="itemgj.cName"
									:value="itemgj.id">
										  <span style="float: left">{{ itemgj.cName }}</span>
									      <span style="float: right; color: #8492a6; font-size: 13px">{{ itemgj.by3 }}</span>
								</el-option>
							</el-select>  
						
						</el-form-item>
					</div>
					
					<!-- 手机号 -->
					<div class="form-item-div" >
						<el-form-item 
							label-position="top"
							label="手机号">
							<el-input  
								class="font15 width465 borderStyle" 
								placeholder="请输入手机号" 
								v-model="item.phoneNumber"
								@input="changePhoneNumber(item.phoneNumber,item,index)"
								></el-input>
						</el-form-item>
						<!-- 座位喜好 -->
						<el-form-item
						    v-show="isShowChooseZuo"
							label-position="top"
							label="座位喜好">
							<el-select 
								class="font15 width215"  
								v-model="item.seat" 
								placeholder="请选择座位喜好"
								@change="chooseSeat(item.seat,item,index)"
								>
							
								<el-option
									v-for="itemgj in seatList"
									:key="itemgj.seatCode"
									:label="itemgj.seatName"
									:value="itemgj.seatCode">
								</el-option>
							</el-select> 
						
						</el-form-item>
					</div>
					
					
				
				</div>
				
				
			</el-form>
			
			
		
			<div class="borderTop" style="padding:40px 20px ;" >
				<p style="display: inline-block;" class="tk-book-psg-tips" v-show="!isShowAddCxr">因公模式不能新增出行人！如果需要新增，请返回上一页！</p>
				<el-button class="el-icon-plus width180 marRt20" round size="medium" v-show="isShowAddCxr" @click="addPassenger"><span style="padding-left:5px;"></span>添加乘机人</el-button>
				<el-button class="el-icon-plus width180 marRt20" round size="medium" v-show="isShowAddCxr" @click="addCxr()"><span style="padding-left:5px;"></span>选择出行人</el-button>
				
			</div>
		</el-card>
		
		
		<!-- 出行人控件 -->
		<yj-cxr-list :maxLength="maxLength" 
			:empInfo="empInfo" 
      :tripType="tripType"
			:visible="visibleCxrList"
			:cxrList="cxrListForYg"
			@cancleCxrList="cancleCxrList"
			@chooseCxrList="chooseCxrList"></yj-cxr-list>
	</div>
</template>

<script>
	import { mapState,mapMutations } from 'vuex';
	export default{
		name: "TkCxr",
		data() {
			return {
				inlineBlock:'inline-block',
				cxrList:[], // 出行人数组
				
				visibleCxrList:false,
				maxLength: 9, // 乘车人数量
				passengerTypeList: [  // 乘车人类型
					{
						id: '1',
						text: '成人'
					}
					// ,{
					// 	id: '2',
					// 	text: '儿童'
					// }
					// ,{
					// 	id: '3',
					// 	text: '婴儿'
					// }
					
				],
				  // 证件类型  1 身份证  2 护照  3 港澳通行证   4 台胞证  5 回乡证  6 台湾通行证  7 学生证  8 军官证  9 其他证件
				idTypeList: [  // 证件类型
					{
						id: '1',
						text: '中国居民身份证',
					},
					{
						id: '2',
						text: '护照',
					},{
						id: '3',
						text: '港澳通行证',
					},{
						id:'4',
						text: '台胞证',
					},
					{
						id:'5',
						text: '回乡证',
					},
					{
						id:'6',
						text: '台湾通行证',
					},{
						id:'7',
						text: '学生证',
					},{
						id:'8',
						text: '军官证',
					},{
						id:'9',
						text: '其他证件',
					},
				],
				countryList:[], // 国家信息列表
				
			}
		},
		props:{
			// 是否显示选座
			isShowChooseZuo:Boolean,
			 // 座位喜好列表
			seatList:{
				type:[Array],
				default(){
					return []
				}
			}
		},
		computed: {
			...mapState({
				tkQuery: state => state.tk.tkQuery,
				cxrListCopy:state=>state.tk.cxrList || [],
			}),
			
			tripType() {
				return this.tkQuery.tripType || 1
			},
			userInfo(){
				let userInfo = this.$common.sessionget('user_info');
				return userInfo || {}
			},
			empInfo() {
				return this.userInfo.empInfo || {}
			},
			vipCorp(){
				return this.userInfo.vipCorp || {}
			},
			// cxrListForYg:[], // 员工控件数据
			cxrListForYg(){
				let cxrList =  this.cxrList.filter((cxrItem,cxrIndex)=>{
					return cxrItem.cxrType == 1
				}) || []
				return cxrList
			},
			
			// 是否显示新增出行人
			isShowAddCxr(){
			  var bool = false;
			  if(this.tkQuery.tripType == 2 ){
			    bool = true;
			  }
			  return  bool
			}
			
		},
		watch:{
			cxrListCopy:{
				immediate: true, // 立即执行
				handler (val, oldVal) {
					// 处理出行人数据
				  var cxrList = this.$common.deepCopy(val);
				  if(cxrList && cxrList.length>0){
					  this.cxrList = cxrList.map((cxrItem,cxrIndex)=>{
					  	  cxrItem.psgType = cxrItem.psgType || '1';
					  	  cxrItem.useCardType = cxrItem.useCardType || '1';
					  	  return cxrItem
					  })
				  }else{
					  this.cxrList = [];
				  }
				 
				  
				},
				deep: true
			}
		},
		mounted() {
			
			this.queryCountry();	
			
			// 处理初始化数据，未及时更新进缓存中的问题
			this.$nextTick(()=>{
				this.SETCxrList(this.cxrList)
			})
		},
		methods: {
			...mapMutations({
				SETCxrList:'tk/setCxrList'
			}),
			// 更新出行人信息  如果传参，就用参数 || 没有就用
			updateCxrList(cxrList) {
				var newCxrList = cxrList || this.cxrList;
				// 格式化数据结构
				this.SETCxrList(newCxrList)
			},
			// 格式化数据结构
			fommatCxrList(cxrList){
				var newCxrList = this.$common.chuLiIdCardList(cxrList,'10901');
				this.updateCxrList(newCxrList)
			},
			//更新出行人证件列表信息
			changeIdcardList(val,item,index,cardIndex,attrName){
				if(attrName == 'validDate'){
					let date = this.$dateTime.YMDFormat(val);
					this.cxrList[index]['idcardList'][cardIndex][attrName] = date;
				}else if(attrName == 'placeOfIssue'){
					this.cxrList[index]['idcardList'][cardIndex][attrName] = val;
					for(var k=0;k<this.countryList.length;k++){
						if(this.countryList[k]['id'] == val){
							this.cxrList[index]['idcardList'][cardIndex]['placeOfIssueName'] = this.countryList[k]['cName'];
							break;
						}
					}
					
					
				}else{
					this.cxrList[index]['idcardList'][cardIndex][attrName] = val;
				}
				
				
				this.updateCxrList();
			},
			// 证件出生日期
			chooseStartDate(e,item,i) {
				let date = this.$dateTime.YMDFormat(e)
				this.cxrList[i].born = date;
				this.updateCxrList();
			},
			// 证件有效期
			chooseEndDate(e,item,i){
				let date = this.$dateTime.YMDFormat(e)
				this.cxrList[i].passportPeriod = date;
				this.updateCxrList();
			},
			// 乘客类型
			updateCxrListType(e,item, i){
				this.cxrList[i].psgType = e;
				this.updateCxrList();
			},
			// 证件类型
			changeUseCardType(idType, item,i){
				this.cxrList[i].useCardType = idType;
				this.updateCxrList();
			},
			// 修改乘机人
			changeCxrName(cxrName,item,i){
				this.cxrList[i].cxrName = cxrName;
				this.updateCxrList();
			},
			// 修改英文姓名
			changeEngName(engName,item,i){
				this.cxrList[i].engName = engName;
				this.updateCxrList();
			},
			// 修改手机号
			changePhoneNumber(phoneNumber,item,i){
				this.cxrList[i].phoneNumber = phoneNumber;
				this.updateCxrList();
			},
			// 选择国籍
			changeNation(nationId,cxrItem,cxrIndex,attrName){
				for(var k=0;k<this.countryList.length;k++){
					if(this.countryList[k]['id'] == nationId){
						this.cxrList[cxrIndex]['nationName'] = this.countryList[k]['cName'];
						break;
					}
				}
				this.updateCxrList();
			},
			// 选中国家
			// chooseCountry(e,i){
				
			// },
			// 获取国家信息
			queryCountry(){
				this.$conn.getConn('commu.getBClass',{jsfile:false})({parNo: 101}).then((res)=>{
					this.countryList = res.data.list || [];
				})
			},
			// 选座
			chooseSeat(seatCode, item,i){
				this.cxrList[i].seat = seatCode;
				this.updateCxrList();
			},
			
			
			// 添加乘车人
			addPassenger() {
				if(this.cxrList.length < this.maxLength){
					let	obj = {

						cxrName: '',
						psgType:'1',// 默认 1  1成人 2儿童 3婴儿 
						cxrType:'3',//当前人的出行人类型   1.员工  2.常旅客 3. 其他
						useCardType:'1',// 当前出行人使用证件类型  1 身份证  2 护照  3 港澳通行证   4 台胞证  5 回乡证  6 台湾通行证  7 学生证  8 军官证  9 其他证件
						phoneNumber:'',
						idNumber:'',
						psgType: '1',
						passportPeriod: '',
						born:'',
					}
					this.cxrList.push(obj)
					this.fommatCxrList(this.cxrList);
				}else {
					this.$message({
						message: `出行人最多选择${this.maxLength}人`,
						type:'error'
					})
				}
				
				
			},
			// 移除旅客
			removePassenger(index) {
				this.cxrList.splice(index,1);
				this.updateCxrList();
			},
			// 选择出行人
			addCxr(){
				this.visibleCxrList = true
			},
			// 取消出行人弹窗
			cancleCxrList(a,b){
				
				this.visibleCxrList = false
			},
			// 获取乘车人信息列表  需要匹配不同类型的出行人
			chooseCxrList(empList){
				let chooseEmpList = empList.map((item,index)=>{
					return item
				})
				// 格式化出行人数据结构
				this.fommatCxrList(chooseEmpList);
				
				this.visibleCxrList = false;
			}
		}
	}
</script>

<style scoped lang="scss">
	.tri-main{
		transition: height 366ms ease 0s;
		height: auto;
		font-family: '微软雅黑';
		.el-card {
			/deep/ .el-card__body{
				padding:30px;
			}
			/deep/ .el-form-item__label{
				font-size: 12px;
				font-weight: 400;
				color: #748197;
			}
			/deep/ .el-input__inner{
				border-top: none;
				border-left:none ;
				border-right: none;
				border-radius: 0;
				padding-left: 0;
				
				font-size: 15px;
				font-weight: 600;
				color: #223344;
				// border-bottom: 1px ;
			}
			
			.form-item-div{
				display: flex;
				justify-content: space-between;
			}
			
			.psg-number{
				font-size:15px;
				font-weight: 600;
				color: #223344
			}
			
		}
		.width180{
			width: 180px;
		}
		.width465{
			width: 465px;
		}
		.width215{
			width:215px ;
		}
		.borderStyle{
			
		}
		
		.tk-book-psg-tips{
			background: #fff4e8;
			    color: #f09a42;
			    font-size: 12px;
			    border-radius: 12px;
			    padding-right: 6px;
		}
		
		
		.marRt20{
			margin-right: 20px;
		}
		
		.pointer{
			cursor: pointer;
		}
		.borderTop{
			border-top: 1px dotted #E6E6E6;
		}
		.padTop20{
			padding-top:20px;
		}
	
		.text-left{
			text-align: left;
		}
		
		.marBtm30{
			margin-bottom: 30px;
		}
		
		
		
		.removeColor{
			color: #4089FF;
		}
		
		.font15{
			font-size: 15px;
		}
		
	
			
			
		
	}
</style>

